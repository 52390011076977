import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
`;

export const Repository = styled.div`
  width: 250px;
  background: #fff;
  border-radius: 3px;
  margin: 0 0 10px 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  animation: blink-2 0.5s both;

  header {
    background: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: -webkit-sticky;
    position: sticky;
    z-index: 9;
    top: 0;

    button {
      border: none;
      font-size: 24px;
      color: #fff;
      background: #cc2900;
      border-radius: 50%;

      width: 32px;
      height: 32px;
      position: relative;
      top: 32px;
      right: -99px;
      margin-top: -32px;
      /* margin-top: -50px; */
      &:hover {
        background: #ff3300;
        cursor: pointer;
      }
    }

    img {
      width: 104px;
      min-height: 104px;
      border-radius: 50%;
    }
    strong {
      font-size: 24px;
      margin-top: 10px;
    }
    small {
      font-size: 14px;
      color: #666;
    }
  }

  ul {
    list-style: none;

    li {
      font-weight: bold;
      padding: 12px 20px;
      white-space: nowrap;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
      small {
        font-weight: normal;
        font-size: 11px;
        color: #999;
        font-style: italic;
      }
      img {
        filter: invert(100%);
        height: 32px;
      }
      &:nth-child(2n -1) {
        background: #f5f5f5;
      }
      &:hover {
        background: #cccccc;
      }
    }
  }
  .selected {
    background: #cccccc !important;
  }
  .maxValue {
    background: #c6e6c7 !important;
  }
`;
