import React from 'react';

const ItemListMax = ({
  data, setOrder, order, topValor, dadosArmas,
}) => (
  <li
    className={
      data.code === order ? 'selected' : data.kills.value === topValor[data.code] ? ' maxValue' : ''
    }
    onClick={() => setOrder('mostUsed')}
  >
    {data.kills.displayValue}
    <small>
      Most Kill Weapon
      <br />
      {dadosArmas.metadata.name}
      <br />
      <img src={dadosArmas.metadata.imageUrl} alt={dadosArmas.metadata.name} />
    </small>
  </li>
);
export default ItemListMax;
