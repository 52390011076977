import React from 'react';

import ItemDetailWeapon from '../ItemDetailWeapon';

const ItemListWeapon = ({
  data, setOrder, order, topValor, dadosArmas,
}) => (
  <li
    className={
      data.code === order ? 'selected' : data.kills.value === topValor[data.code] ? ' maxValue' : ''
    }
    onClick={() => setOrder(data.code)}
  >
    {data.kills.value} ({data.shotsFired.displayValue})<small>{dadosArmas.metadata.name} </small>
    {data.code === order ? <ItemDetailWeapon data={data} weapon={dadosArmas} /> : ''}
  </li>
);
export default ItemListWeapon;
