import React from "react";

import moment from "moment";
import { Container, Repository } from "./styles";
import ItemList from "../ItemList";
import ItemListWeapon from "../ItemListWeapon";
import ItemListMax from "../ItemListMax";
import GameList from "../GameList";

const CompareList = ({
  players,
  removePlayer,
  armasAux,
  topValor,
  setOrder,
  order = "scorePerMinute",
  dadosArmas,
  membros,
}) => (
  <Container>
    {players

      .sort(
        (a, b) =>
          (b.data.stats[order]
            ? b.data.stats[order].value
            : b.data.weapons[order]
            ? b.data.weapons[order].kills.value === 0
              ? b.data.weapons[order].shotsFired.value
              : b.data.weapons[order].kills.value
            : 0) -
          (a.data.stats[order]
            ? a.data.stats[order].value
            : a.data.weapons[order]
            ? a.data.weapons[order].kills.value === 0
              ? a.data.weapons[order].shotsFired.value
              : a.data.weapons[order].kills.value
            : 0)
      )
      .map((player) => (
        <Repository key={player.externalId}>
          <header>
            <button
              type="button"
              onClick={() => removePlayer(player.platformUserIdentifier)}
            >
              <i className="fa fa-trash" />
            </button>
            <a
              href={`https://battlefieldtracker.com/bfv/profile/origin/${player.platformUserIdentifier}/overview`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={player.avatarUrl} alt={player.platformUserIdentifier} />
            </a>
            <strong>
              {membros.includes(player.platformUserIdentifier) ? "[XoRo]" : ""}
              {player.platformUserIdentifier}
            </strong>
            <small>{moment(player.lastUpdated).fromNow()}</small>
          </header>
          <ul>
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.rank}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scorePerMinute}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.timePlayed}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.kdRatio}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.kills}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.deaths}
            />
            <GameList
              player={player.platformUserIdentifier}
              update={player.lastUpdated}
            />
            <ItemListMax
              key="mostUsed"
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.max}
              dadosArmas={
                dadosArmas[player.max.code2] || { metadata: { name: "" } }
              }
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.damage}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.assists}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.killsAggregated}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.shotsTaken}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.shotsHit}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.shotsAccuracy}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.killStreak}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.dogtagsTaken}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.avengerKills}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.saviorKills}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.headshots}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.suppressionAssists}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.longestHeadshot}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.killsPerMinute}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.damagePerMinute}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.heals}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.revives}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.revivesRecieved}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.resupplies}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.repairs}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.squadSpawns}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.ordersCompleted}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.wlPercentage}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.wins}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.losses}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.rounds}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.roundsPlayed}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreRound}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreGeneral}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreCombat}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreDefensive}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreObjective}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreBonus}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreSquad}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreAward}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreAssault}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreMedic}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreSupport}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreRecon}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreAir}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreLand}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreTanks}
            />
            <ItemList
              topValor={topValor}
              order={order}
              setOrder={setOrder}
              data={player.data.stats.scoreTransports}
            />
            {armasAux.map((arma) => (
              <ItemListWeapon
                key={arma}
                topValor={topValor}
                order={order}
                setOrder={setOrder}
                data={
                  player.data.weapons[arma] || {
                    code: arma,
                    kills: { value: 0, displayValue: 0 },
                    shotsFired: { displayValue: 0 },
                  }
                }
                dadosArmas={dadosArmas[arma] || { metadata: { name: "" } }}
              />
            ))}
          </ul>
        </Repository>
      ))}
  </Container>
);

export default CompareList;
