import React from 'react';

const ItemList = ({
  data, setOrder, order, topValor,
}) => (
  <li
    className={
      data.key.charAt(0).toLowerCase() + data.key.slice(1) === order
        ? 'selected'
        : data.value === topValor[data.key.charAt(0).toLowerCase() + data.key.slice(1)]
          ? ' maxValue'
          : ''
    }
    onClick={() => setOrder(data.key.charAt(0).toLowerCase() + data.key.slice(1))}
  >
    {data.displayValue}
    <small>{data.displayName}</small>
  </li>
);
export default ItemList;
