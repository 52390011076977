import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import ReactGA from 'react-ga';
import api from '../../services/api';

import { Container, Form, Logo } from './styles';
import CompareList from '../../components/CompareList';

import 'font-awesome/css/font-awesome.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';

import logo from './logo.png';

ReactGA.initialize('UA-305160-33');
ReactGA.pageview(window.location.pathname + window.location.search);

const armasAux2 = [];
const topValor = {};

const membros = [
  'fitinge',
  'fmthiago',
  'FguareziBR',
  'VitorScarKill',
  'AEffting_BR',
  'hbaldin87',
  'MrLikid',
  'Nooiisee--',
  'RaTaZaana',
  'Srpenter',
];

export default class Main extends Component {
  state = {
    repoInput: '',
    repositoryError: false,
    playersUsers: JSON.parse(localStorage.getItem('players')) || membros,
    dataPlayers: JSON.parse(localStorage.getItem('dataPlayers')) || [],
    armasAux: JSON.parse(localStorage.getItem('weapons')) || [],
    weaponsInfo: JSON.parse(localStorage.getItem('weaponsInfo')) || {},
    order: 'kdRatio',
  };

  componentDidMount() {
    const { dataPlayers, playersUsers } = this.state;
    const dataPlayersAux = Object.keys(dataPlayers);
    const adicionarPlayers = playersUsers.filter(
      x => !dataPlayersAux.includes(x),
    );
    Object.values(dataPlayers).forEach((player) => {
      if (moment(player.cacheExpiresAt) < moment()) {
        this.getData(player.platformUserIdentifier);
      }
    });
    adicionarPlayers.forEach((user) => {
      this.getData(user);
    });
  }

  async getArmas(user) {
    const { weaponsInfo } = this.state;
    const { data: armas } = await api.get(`/api/profile/origin/${user}/weapons`);
    armas.data.children.forEach((arma) => {
      const code = arma.id.substr(8);
      if (armasAux2.indexOf(code) === -1) {
        armasAux2.push(code);
      }
      weaponsInfo[code] = arma;
    });
    this.setState({ weaponsInfo, armasAux: armasAux2 });

    localStorage.setItem('weaponsInfo', JSON.stringify(weaponsInfo));
    localStorage.setItem('weapons', JSON.stringify(armasAux2));
  }

  async getData(user) {
    this.setState({ loading: true });

    const armas = {};

    armasAux2.forEach((code) => {
      const auxArma = {
        code,
        kills: { value: 0, displayValue: 0 },
        shotsFired: { displayValue: 0 },
      };
      armas[code] = auxArma;
    });

    const { playersUsers } = this.state;
    try {
      const { data: player } = await api.get(`/api/profile/origin/${user}`);
      if (player.status !== 1) {
        throw player;
      }

      this.getArmas(user);
      ReactGA.event({
        category: 'Consulta',
        action: `/stats/${user}`,
      });

      Object.keys(player.data.stats).forEach((stat) => {
        if (stat !== 'id' && stat !== 'lastUpdated' && stat !== 'platformId') {
          this.setaTopValor(stat, player.data.stats[stat].value);
        }
      });

      player.data.weapons.forEach((arma) => {
        armas[arma.code] = arma;
      });

      // prettier-ignore
      const max = player.data.weapons.reduce(
        (prev, current) => (prev.kills.value > current.kills.value ? prev : current),
      );

      const max2 = JSON.parse(JSON.stringify(max));

      max2.code2 = max2.code;
      max2.code = 'mostUsed';
      player.max = max2;
      this.setaTopValor('mostUsed', max2.kills.value);

      armas.mostUsed = max2;

      player.data.weapons = armas;
      armasAux2.forEach((code) => {
        if (code in player.data.weapons) {
          this.setaTopValor(code, player.data.weapons[code].kills.value);
        }
      });

      this.setState(state => ({
        repoInput: '',

        playersUsers: [
          ...new Set([...playersUsers, player.platformUserIdentifier]),
        ],
        repositoryError: false,
        dataPlayers: {
          ...state.dataPlayers,
          [player.platformUserIdentifier]: player,
        },
      }));
      const { dataPlayers } = this.state;
      localStorage.setItem('dataPlayers', JSON.stringify(dataPlayers));
      localStorage.setItem(
        'players',
        JSON.stringify([
          ...new Set([...playersUsers, player.platformUserIdentifier]),
        ]),
      );
    } catch (err) {
      this.setState({ repositoryError: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleAddRepo = async (e) => {
    const { repoInput, playersUsers } = this.state;

    e.preventDefault();
    this.setState({ loading: true });

    if (repoInput === '') {
      this.setState({ loading: false, repositoryError: true });
      return;
    }

    const index = playersUsers.indexOf(repoInput);
    if (index > -1) {
      this.setState({ loading: false, repositoryError: true });
      return;
    }

    this.getData(repoInput);
  };

  removePlayer = (user) => {
    const { playersUsers, dataPlayers } = this.state;

    delete dataPlayers[user];

    const index = playersUsers.indexOf(user);
    if (index > -1) {
      playersUsers.splice(index, 1);
    }
    ReactGA.event({
      category: 'Remove Usuário',
      action: user,
    });

    // eslint-disable-next-line react/no-unused-state
    this.setState({ players: dataPlayers });
    if (playersUsers.length > 0) {
      localStorage.setItem('players', JSON.stringify(playersUsers));
      localStorage.setItem('dataPlayers', JSON.stringify(dataPlayers));
    } else {
      localStorage.removeItem('players');
      localStorage.removeItem('dataPlayers');
    }
  };

  setOrder = (order) => {
    ReactGA.event({
      category: 'Ordena',
      action: order,
    });
    this.setState({ order });
  };

  setaTopValor = (chave, valor) => {
    if (topValor[chave] === undefined) {
      topValor[chave] = 0;
    }
    if (topValor[chave] < valor) {
      topValor[chave] = valor;
    }
  };

  getTopValor = () => {
    const { dataPlayers, weaponsInfo } = this.state;

    const players = Object.values(dataPlayers);
    players.forEach((player) => {
      Object.keys(player.data.stats).forEach((stat) => {
        if (stat !== 'id' && stat !== 'lastUpdated' && stat !== 'platformId') {
          this.setaTopValor(stat, player.data.stats[stat].value);
        }
      });

      Object.keys(weaponsInfo).forEach((code) => {
        if (code in player.data.weapons) {
          this.setaTopValor(code, player.data.weapons[code].kills.value);
        }
      });
    });
  };

  render() {
    this.getTopValor();
    const {
      dataPlayers,
      order,
      repoInput,
      loading,
      repositoryError,
      weaponsInfo,
      armasAux,
    } = this.state;
    const players = Object.values(dataPlayers);
    return (
      <Container>
        <Logo src={logo} />

        <Form withError={repositoryError} onSubmit={this.handleAddRepo}>

          <input
            type="text"
            placeholder="usuário"
            value={repoInput}
            onChange={e => this.setState({ repoInput: e.target.value })}
          />
          <button type="submit">
            {loading ? <i className="fa fa-spinner fa-pulse" /> : 'OK'}
          </button>
          <a
            href="https://www.youtube.com/channel/UC_oAu9A9wuXj6v88TRviv_A/"
            target="_blank"
            className="btnWhatsapp"
          >
            <i className="fa fa-youtube" />
          </a>
          <a
            href="http://discord.gg/Qya9Ydw"
            target="_blank"
            className="btnDiscord"
            rel="noopener noreferrer"
          >
            <i className="fab fa-discord" />
          </a>

          <a
            href="https://www.facebook.com/xoro.com.br"
            target="_blank"
            className="btnDiscord"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f" />
          </a>

          <a href="https://xoro.com.br/grafico" className="btnWhatsapp">
            <i className="fa fa-chart-pie" />
          </a>
        </Form>

        <CompareList
          order={order}
          setOrder={this.setOrder}
          removePlayer={this.removePlayer}
          players={players}
          topValor={topValor}
          armasAux={armasAux}
          dadosArmas={weaponsInfo}
          membros={membros}
        />
      </Container>
    );
  }
}
