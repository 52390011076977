import React, { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../../services/api';

function GameList({ player, update }) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    api
      .get(`/gamereports/${player}?update=${moment(update).unix()}`)
      .then((res) => {
        setGames(res.data);
      });
  }, [player, update]);

  return (
    <li>
      <small>Últimas Partidas</small>

      <table width="100%" height="115">
        <tr>
          <th>
            <small>Data</small>
          </th>
          <th align="right">
            <small>Pos</small>
          </th>
          <th align="right">
            <small>K</small>
          </th>
          <th align="right">
            <small>D</small>
          </th>
          <th align="right">
            <small>A</small>
          </th>
          <th align="right">
            <small>HS</small>
          </th>

          <th align="right">
            <small>W/L</small>
          </th>
        </tr>
        {games.length ? (
          games.map(game => (
            <tr title={`${game.mapa} - ${game.server}`}>
              <td>
                <small>{moment.unix(game.data).fromNow()}</small>
              </td>
              <td align="right">{game.posicao}º</td>
              {game.deaths > game.kills ? (
                <td align="right" style={{ color: '#ff0000' }}>
                  {game.kills}
                </td>
              ) : (
                <td align="right">{game.kills}</td>
              )}
              {game.deaths > game.kills ? (
                <td align="right" style={{ color: '#ff0000' }}>
                  {game.deaths}
                </td>
              ) : (
                <td align="right">{game.deaths}</td>
              )}
              <td align="right">{game.ka_kills}</td>
              <td align="right">{game.headshots}</td>
              <td align="right">{game.winnner ? 'W' : 'L'}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} align="center">
              Carregando Partidas
            </td>
          </tr>
        )}
      </table>
    </li>
  );
}

export default GameList;
