import styled from 'styled-components';

export const Logo = styled.img`
  height: 120px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 10px;
  h1 {
    color: #fff;
  }
`;

export const Form = styled.form`
  margin-top: 10px;
  width: 100%;
  max-width: 90%;
  display: flex;

  input {
    flex: 1;
    height: 55px;
    padding: 0 20px;
    background: #fff;
    font-size: 18px;
    color: #444;
    border-radius: 3px;
    border: ${props => (props.withError ? '2px solid #f00' : 0)};
  }
  .btnDiscord {
    background: #4465b7 !important;
    margin-left: 10px;
    height: 55px;
    padding: 0 20px;
    color: #fff;
    border: 0;
    font-size: 28px;
    font-weight: bold;
    border-radius: 3px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
  }
  .btnWhatsapp {
    background: #e03d3a !important;
    margin-left: 10px;
    height: 55px;
    padding: 0 20px;
    color: #fff;
    border: 0;
    font-size: 28px;
    font-weight: bold;
    border-radius: 3px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
  }

  button {
    height: 55px;
    padding: 0 20px;
    margin-left: 10px;
    background: #63f5b0;
    color: #fff;
    border: 0;
    font-size: 20px;
    font-weight: bold;
    border-radius: 3px;
    width: 80px;
    &:hover {
      background: #52d89f;
    }
  }
`;
