import React from "react";

const ItemDetailWeapon = ({ data, weapon }) => (
  <div>
    <img src={weapon.metadata.imageUrl} alt={weapon.metadata.name} />
    <br />
    {weapon.metadata.categoryName || weapon.metadata.categoryKey}
    <br />
    <small>
      Kills: {data.kills.displayValue} <br />
      Shots Fired: {data.shotsFired.displayValue} <br />
      Shots Hit: {data.shotsHit ? data.shotsHit.displayValue : 0} <br />
      Shots Accuracy: {data.shotsAccuracy ? data.shotsAccuracy.displayValue : 0}
      % <br />
      Kills per Minute:{" "}
      {data.killsPerMinute ? data.killsPerMinute.displayValue : 0} <br />
      Headshots: {data.headshots ? data.headshots.displayValue : 0} <br />
      Time Played: {data.timePlayed ? data.timePlayed.displayValue : 0} <br />
    </small>
  </div>
);

export default ItemDetailWeapon;
